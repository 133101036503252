<template>
  <div>
    <base-sign-page
      title-text="服务器命令执行记录"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="dataList"
      :search-parms="serverList.length > 0 ? searchParms : []"
      @clickSearch="loadData"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      v-loadingx="loading"
      :need-pagination="true">
    </base-sign-page>
    <slot></slot>
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'

import {
  serverRequest,
  serverCmdLogRequest
} from '@/api'

export default {
  name: 'SignPage',
  components: {
    BaseSignPage
  },
  computed: {
    columnList () {
      return [{
        field: 'createTime',
        title: '操作时间',
      },
      {
        field: 'userName',
        title: '操作人'
      },
      {
        field: 'cmdTypeName',
        title: '命令模版'
      },
      {
        field: 'config',
        title: '配置'
      },
      {
        field: 'result',
        title: '结果'
      },
      {
        field: 'isSuccessText',
        title: '是否成功'
      }]
    },
    searchParms () {
      let data = [{
        type: 'select',
        placeholder: '服务器',
        selectDatas: this.serverList,
        key: 'serverId',
        default: Number(this.$router.history.current.query.serverId || 0) || null
      },
      {
        type: 'select',
        placeholder: '命令模版',
        selectDatas: this.$store.getters.cmdTypeList,
        key: 'cmdTypeId'
      }]
      return data
    }
  },
  created () {
    this.searchData.serverId = Number(this.$router.history.current.query.serverId || 0) || null
    this.loadData()
    this.loadServerList()
    this.$store.dispatch('loadCmdTypeList')
  },
  methods: {
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.formParms = this.formParmsUpdate
        this.openDialog = true
      } else if (data.action === 'del') {
        this.delData()
      } else {
        this.$emit('tableAction', data)
      }
    },
    loadServerList () {
      serverRequest.get().then((data) => {
        this.serverList = data.map((v) => {
          return {
            key: v.id,
            label: v.name
          }
        })
      })
    },
    loadData (searchParm) {
      this.searchData = searchParm || this.searchData
      if (!this.searchData.serverId) {
        this.$notice.info({
          title: '系统提示',
          desc: '请选择服务器'
        })
        return
      }
      this.loading = true
      serverCmdLogRequest.get(this.searchData).then((data) => {
        data.forEach(v => v.isSuccessText = v.isSuccess ? '成功' : '失败')
        this.dataList = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  data () {
    return {
      serverList: [],
      formParms: null,
      chooseList: [],
      searchData: {},
      dataList: [],
      noteText: '',
      loading: false,
      chooseData: null,
      openDialog: false,
      openDialogFile: false
    }
  }
}
</script>
